<template>
    <div class="info">
        <div class="font-weight-bold name">
            <template v-if="isBundle">
                {{
                    translate('cart.bundle.productName', {
                        productName: $product.getName(product),
                    })
                }}
            </template>
            <template v-else>{{ $product.getName(product) }}</template>
        </div>
        <div v-if="isBundle" class="d-flex align-items-center">
            <UIIcon :name="['far', 'box']" class="box-icon mr-2"></UIIcon>
            <span class="text-light small">{{
                translate('cart.bundle.size', {
                    bundleSize,
                })
            }}</span>
        </div>
        <div class="delivery">
            <div class="d-flex align-items-center">
                <template>
                    <Envelope class="envelope-icon mr-2" />
                    <span class="text-light small">{{ translate('cart.delivery.type') }}</span>
                </template>
            </div>
        </div>
        <div v-if="product.type === 'region'">
            <div v-if="product.regionCode === 'ww'" class="d-flex align-items-center">
                <UIFlag code="ww" class="mr-2 global" />
                <span class="text-light small">{{ translate('product.globallyRedeemable') }}</span>
            </div>
            <div v-else class="d-flex align-items-center">
                <UIFlag :code="product.regionCode" class="mr-2" />
                <span class="text-light small">{{
                    translate('cart.deliveryInCountry', {
                        country: translate('country.' + product.regionCode.toUpperCase()),
                    })
                }}</span>
            </div>
        </div>
        <div v-if="product.type === 'currency'">
            <div v-if="product.currencyLocked" class="d-flex align-items-center">
                <UIFlag :code="product.currencyCode" class="mr-2" />
                <span class="text-light small">{{
                    translate('product.currencyLocked', { currency: product.currencyCode.toUpperCase() })
                }}</span>
            </div>
            <div v-else class="d-flex align-items-center">
                <UIFlag code="ww" class="global mr-2" />
                <span class="text-light small">{{ translate('product.globallyRedeemable') }}</span>
            </div>
        </div>
        <LoyaltyCoinsPill
            v-if="loyaltyPreview && product.dundleCoinsRewarded"
            :coins-earned="product.dundleCoinsRewarded"
            class="d-none d-md-flex mt-1"
        />
    </div>
</template>
<script>
import { UIFlag, UIIcon } from '@dundle/ui/components';
import Envelope from '~/assets/icons/envelope.svg';
import LoyaltyCoinsPill from '~/components/loyalty/LoyaltyCoinsPill';
import TranslationMixin from '~/mixins/TranslationMixin';
import { productIsBundle, getBundleSize } from '~/utils/cart/';

export default {
    components: {
        Envelope,
        UIFlag,
        LoyaltyCoinsPill,
        UIIcon,
    },

    mixins: [TranslationMixin],
    props: ['product', 'loyaltyPreview'],
    computed: {
        isBundle() {
            return productIsBundle({ product: this.product });
        },
        bundleSize() {
            return getBundleSize({ product: this.product });
        },
    },
};
</script>

<style lang="scss" scoped>
.delivery {
    .envelope-icon {
        color: $color-primary;
    }
    svg {
        height: 0.88rem;
    }
}

.box-icon {
    width: 1.3rem;
    height: 1.3rem;
    color: $color-primary;
}

.global::after {
    box-shadow: none !important;
}

.coins {
    .coin {
        width: 1.333333rem;
    }

    span {
        &:hover {
            color: $color-primary;
            text-decoration: underline;
        }
    }
}
</style>

<i18n src="@dundle/locale/data/keywords.json"></i18n>
<i18n src="@dundle/locale/data/countries.json"></i18n>
<i18n src="@dundle/locale/data/cart.json"></i18n>
<i18n src="@dundle/locale/data/loyalty.json"></i18n>
