<template>
    <div class="x-product-image">
        <div class="product-image-container cover">
            <UIImage
                :lazy="lazy"
                :resource="true"
                :src="$product.getImage480(product)"
                fit="cover"
                :alt="altText"
                :width="width"
                :height="height"
                :ratio="ratio"
                :responsive-widths="responsiveWidths"
                :sizes="sizes"
            />

            <div class="inner"></div>
        </div>

        <UIRibbon :product="product" />
    </div>
</template>
<script>
import { UIImage, UIRibbon } from '@dundle/ui/components';
export default {
    name: 'XProductimage',

    components: {
        UIImage,
        UIRibbon,
    },

    props: {
        width: Number,
        height: Number,
        responsiveWidths: Array,
        product: Object,
        sizes: String,
        discount: {
            type: Boolean,
            default: false,
        },
        alternativeLink: {
            type: Boolean,
            required: false,
        },
        lazy: {
            type: Boolean,
            required: false,
            default: true,
        },
    },

    computed: {
        altText() {
            return this.$product.getName(this.product);
        },

        ratio() {
            if ((this.width || this.height) && !this.responsiveWidths) {
                return '2:3';
            }
            return null;
        },
    },
};
</script>
<style lang="scss" scoped>
@import '../../assets/styles/imageUtils.scss';

.x-product-image {
    width: 100%;
}

.product-image-container {
    background-size: cover !important;
    @include calculate-image-border-radius(10%);

    overflow: hidden;
    position: relative;
    border: 1px solid $color-grey-3;

    img {
        position: absolute;
        width: 100%;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }

    .outer {
        overflow: visible;
    }
    .inner {
        overflow: hidden;
        position: relative;
        width: 100%;
        height: 100%;
        padding-top: 70%;
        border-radius: 4px;
    }

    .discount {
        z-index: 1;
        position: absolute;
        top: -20px;
        left: -20px;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        line-height: 15px;
        padding: 15px 0;
        color: white;
        font-weight: bold;
        background: $color-discount;
        background: linear-gradient(to bottom, $color-discount 0%, darken($color-discount, 10%) 100%);
        transform: rotate(-20deg);
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3), inset 0 0 2px rgba(0, 0, 0, 0.4);
        .price {
            font-size: 1rem;
        }
        .subText {
            font-size: 0.7rem;
            font-weight: normal;
        }
    }
    @media screen and (max-width: 575px) {
        .discount {
            left: -10px;
        }
    }
}
.cover-container {
    display: flex;
    justify-content: center;
    align-content: center;
    margin-right: 0;
}

.cover-spacing {
    width: 5.0625rem;
}
</style>
