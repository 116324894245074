<template>
    <UIRipple
        class="x-product-item pt-3 pb-3"
        color="black"
        :opacity="0"
        :tag="noLink ? 'div' : 'nuxt-link'"
        :to="$product.getPath(product)"
        :title="$product.getName(product)"
        :rel="shouldFollow"
        @click.native="catchClickEvent(product)"
    >
        <div class="d-flex flex-wrap flex-md-nowrap">
            <div class="d-flex align-items-center flex-grow-1">
                <div class="fixed-width mr-3">
                    <ProductImage
                        class="w-100"
                        :product="product"
                        :alternative-link="alternativeLink"
                        :width="110"
                        :lazy="false"
                    />
                </div>

                <ProductDetails :loyalty-preview="loyaltyPreview" :product="product" class="flex-grow-1" />
            </div>
            <LoyaltyCoinsPill
                v-if="loyaltyPreview && product.dundleCoinsRewarded"
                :coins-earned="product.dundleCoinsRewarded"
                class="mt-3 d-md-none"
            />
            <hr class="d-block d-sm-none w-100" />
            <div class="d-flex align-items-center ml-auto">
                <div class="pricing position-relative text-center w-100">
                    <div class="d-flex flex-column mr-3">
                        <span v-if="isLoyaltyPurchase" class="price font-weight-bold">
                            {{ coinPrice }} {{ translate('loyalty.coins') }}
                        </span>
                        <template v-else>
                            <span v-if="showDiscount" class="old-price d-none">{{
                                $formatCurrency($product.getValue(product))
                            }}</span>
                            <span v-if="showDiscount" class="price discount-price font-weight-bold ml-auto">
                                {{ $formatCurrency($product.getValue(product)) }}
                            </span>
                            <span class="price d-block font-weight-bold" :class="showDiscount ? 'discount' : ''">
                                {{ $formatCurrency($product.getPrice(product)) }}
                            </span>
                        </template>
                    </div>
                </div>
                <UIButton
                    :variant="buttonVariant"
                    :disabled="isOutOfStock || notEnoughCoins"
                    @click.native.stop.prevent="redeemProduct"
                >
                    {{ buttonText }}
                </UIButton>
            </div>
        </div>
    </UIRipple>
</template>

<script>
import { UIButton, UIRipple } from '@dundle/ui/components';
import { mapActions, mapGetters } from 'vuex';
import { ProductImage, ProductDetails } from '~/components/product';
import LoyaltyCoinsPill from '~/components/loyalty/LoyaltyCoinsPill';
import TranslationMixin from '~/mixins/TranslationMixin';
import SecurityMixin from '~/mixins/SecurityMixin';

export default {
    components: {
        UIRipple,
        UIButton,
        ProductImage,
        ProductDetails,
        LoyaltyCoinsPill,
    },
    mixins: [TranslationMixin, SecurityMixin],

    props: {
        product: {
            type: Object,
            required: true,
        },
        loyaltyPreview: {
            default: true,
        },
        noLink: {
            default: false,
        },
        email: {
            type: String,
        },
        alternativeLink: {
            type: Boolean,
        },
        isLoyaltyPurchase: {
            default: false,
        },
    },

    computed: {
        ...mapGetters({
            detectedRegion: 'locale/detectedRegion',
            isLocal: 'ip/isLocal',
            coins: 'data/getTotalCoins',
        }),

        shouldFollow() {
            const indexedProducts = [
                'neosurf',
                'zalando',
                'itunes',
                'netflix',
                'playstation',
                'roblox',
                'spotify',
                'playstation-plus',
                'pubg-mobile-uc',
            ];
            const isNLRegion =
                this.$locale.iso === 'nl-NL' &&
                (this.product.brand.selectedRegion === 'nl' || this.product.brand.selectedCurrency === 'eur');
            const isITRegion =
                this.$locale.iso === 'it-IT' &&
                (this.product.brand.selectedRegion === 'it' || this.product.brand.selectedCurrency === 'eur');

            return indexedProducts.includes(this.product.brand.slug) && (isNLRegion || isITRegion)
                ? undefined
                : 'nofollow';
        },

        isOutOfStock() {
            return this.product.stock === 0;
        },

        isMaxQuantity() {
            return this.$cart.items.some(el => el.productId === this.product.productId) && this.product.stock === 1;
        },

        allowsCrossBorder() {
            return this.$brand.allowsCrossBorder(this.product.brand.slug);
        },

        coinPrice() {
            return this.$product.getCoinPrice(this.product);
        },

        buttonVariant() {
            if (this.isOutOfStock) {
                return 'default';
            } else if (this.isLoyaltyPurchase) {
                return 'loyalty';
            } else {
                return 'primary';
            }
        },

        buttonText() {
            if (this.isLoyaltyPurchase) {
                if (this.notEnoughCoins) {
                    return `${this.coins} / ${this.coinPrice}`;
                } else if (this.isOutOfStock) {
                    return this.translate('button.loyaltyOutOfStock');
                } else {
                    return this.translate('button.loyaltyRedeem');
                }
            } else if (this.isOutOfStock) {
                return this.translate('product.stock');
            } else {
                return this.translate('button.buy');
            }
        },

        notEnoughCoins() {
            return this.coins && this.isLoyaltyPurchase ? this.coinPrice > this.coins : false;
        },

        showDiscount() {
            return this.$product.getDiscount(this.product) > 0.005;
        },
    },

    methods: {
        ...mapActions({
            addProductInCart: 'cart/addItem',
            showModal: 'ui/showModal',
        }),

        catchClickEvent(product) {
            if (!this.alternativeLink) {
                this.$gtm?.push({ event: 'event_brand_to_product_page_button', value: this.product.productId });
                this.$analytics?.viewItem([product], false, this.$locale.currency, product.price);
            }
        },

        redeemProduct() {
            if (this.isLoyaltyPurchase) {
                if (!this.isMicrosoftApproved) {
                    this.showModal({ name: 'product-cross-border', data: { currency: this.product.currencyCode } });
                } else if (this.disallowCrossBorder && !this.locatedInCorrectRegion) {
                    this.showModal({ name: 'product-cross-border', data: { region: this.product.regionCode } });
                } else {
                    this.showModal({
                        name: 'coins-redeem',
                        data: { product: this.product, coins: this.coins, email: this.email },
                    });
                }
                return;
            }
            this.addToCart();
        },

        addToCart() {
            if (!this.isMicrosoftApproved) {
                return this.showModal({ name: 'product-cross-border', data: { currency: this.product.currencyCode } });
            }

            if (!this.isLocal && this.disallowCrossBorder && !this.locatedInCorrectRegion) {
                return this.showModal({ name: 'product-cross-border', data: { region: this.product.regionCode } });
            }

            if (this.isBrandBlockedInCountry) {
                return this.showModal({
                    name: 'info',
                    data: {
                        title: this.translate('notavailableincountry.title'),
                        message: this.translate('notavailableincountry.message'),
                        button: this.translate('button.okconfirm'),
                    },
                });
            }

            if (this.isMaxQuantity || this.isQuantityRestricted) {
                return this.showModal('product-max-items');
            }

            if (
                (['pcs', 'transcash', 'razer-gold'].includes(this.product.brand.slug) ||
                    (this.product.brand.slug === 'neosurf' && this.product.regionCode === 'gb')) &&
                !this.$cookies.get('deniedFraudWarning')
            ) {
                this.$gtm?.push({ event: 'event_fraud_warning_modal_triggered' });
                return this.showModal({ name: 'fraud-warning', isMediumModal: true, data: { product: this.product } });
            }

            if (this.showMultipleBrandsBlockedModal) {
                return this.showModal({
                    name: 'to-cart',
                    data: {
                        title: this.translate('multipleBrands.title'),
                        message: this.translate('multipleBrands.message'),
                        button: this.translate('button.showCart'),
                    },
                });
            }

            const promise = this.addProductInCart(this.product);

            // push call to action button to gtm on the brand page
            this.$gtm?.push({ event: 'event_brand_page_button', value: this.product.brand.slug });
            this.$analytics?.selectItemFromItemList('brand_page', 'Brand Page', [this.product]);

            return promise.then(() => {
                return this.$navigation.goto(this.$locale.path('cart'));
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.x-product-item {
    display: block;
    background-color: #fff;
    border-bottom: 1px solid $color-grey-3;

    @media screen and (max-width: 767px) {
        &:not(:first-child) {
            margin-top: 10px;
        }

        &:not(:last-child) {
            margin-bottom: 10px;
        }
    }
    &:hover {
        background-color: $color-primary-light;
    }

    .discount-wrapper {
        width: 5rem;
        height: 5rem;
        position: absolute;
        right: -4px;
        top: -4px;
        .discount-text {
            position: absolute;
            right: 0px;
            top: 22%;
            transform: rotate(45deg);
            color: $color-text-inverted;
        }
        .discount-image {
            width: 100%;
        }
    }
    .fixed-width {
        width: 110px;
        position: relative;
    }

    &:hover,
    &:active {
        text-decoration: none;
    }

    .price {
        font-size: 1.25rem;
        margin-left: 0.3rem;
        &.discount-price {
            font-size: 0.93rem;
            text-decoration: line-through;
        }
        &.discount {
            color: $color-price;
        }
    }
    button {
        min-width: 150px;
    }

    &.out-of-stock {
        button {
            display: none !important;
        }
    }

    @media screen and (max-width: 768px) {
        padding: 0 15px;
        .touch-UIRipple {
            display: none;
        }
    }
}
</style>

<i18n src="@dundle/locale/data/keywords.json"></i18n>
<i18n src="@dundle/locale/data/buttons.json"></i18n>
<i18n src="@dundle/locale/data/cart.json"></i18n>
<i18n src="@dundle/locale/data/loyalty.json"></i18n>
<i18n src="@dundle/locale/data/warnings.json"></i18n>
<i18n src="@dundle/locale/data/notavailable.json"></i18n>
