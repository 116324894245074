import { render, staticRenderFns } from "./ProductItem.vue?vue&type=template&id=aaf63812&scoped=true"
import script from "./ProductItem.vue?vue&type=script&lang=js"
export * from "./ProductItem.vue?vue&type=script&lang=js"
import style0 from "./ProductItem.vue?vue&type=style&index=0&id=aaf63812&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aaf63812",
  null
  
)

/* custom blocks */
import block0 from "@dundle/locale/data/keywords.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fbuilds%2Fkorsit-bv%2Fdundle%2Fservice%2Fdundle.com%2Fcomponents%2Fproduct%2FProductItem.vue&external"
if (typeof block0 === 'function') block0(component)
import block1 from "@dundle/locale/data/buttons.json?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fbuilds%2Fkorsit-bv%2Fdundle%2Fservice%2Fdundle.com%2Fcomponents%2Fproduct%2FProductItem.vue&external"
if (typeof block1 === 'function') block1(component)
import block2 from "@dundle/locale/data/cart.json?vue&type=custom&index=2&blockType=i18n&issuerPath=%2Fbuilds%2Fkorsit-bv%2Fdundle%2Fservice%2Fdundle.com%2Fcomponents%2Fproduct%2FProductItem.vue&external"
if (typeof block2 === 'function') block2(component)
import block3 from "@dundle/locale/data/loyalty.json?vue&type=custom&index=3&blockType=i18n&issuerPath=%2Fbuilds%2Fkorsit-bv%2Fdundle%2Fservice%2Fdundle.com%2Fcomponents%2Fproduct%2FProductItem.vue&external"
if (typeof block3 === 'function') block3(component)
import block4 from "@dundle/locale/data/warnings.json?vue&type=custom&index=4&blockType=i18n&issuerPath=%2Fbuilds%2Fkorsit-bv%2Fdundle%2Fservice%2Fdundle.com%2Fcomponents%2Fproduct%2FProductItem.vue&external"
if (typeof block4 === 'function') block4(component)
import block5 from "@dundle/locale/data/notavailable.json?vue&type=custom&index=5&blockType=i18n&issuerPath=%2Fbuilds%2Fkorsit-bv%2Fdundle%2Fservice%2Fdundle.com%2Fcomponents%2Fproduct%2FProductItem.vue&external"
if (typeof block5 === 'function') block5(component)

export default component.exports