var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info"},[_c('div',{staticClass:"font-weight-bold name"},[(_vm.isBundle)?[_vm._v("\n            "+_vm._s(_vm.translate('cart.bundle.productName', {
                    productName: _vm.$product.getName(_vm.product),
                }))+"\n        ")]:[_vm._v(_vm._s(_vm.$product.getName(_vm.product)))]],2),_vm._v(" "),(_vm.isBundle)?_c('div',{staticClass:"d-flex align-items-center"},[_c('UIIcon',{staticClass:"box-icon mr-2",attrs:{"name":['far', 'box']}}),_vm._v(" "),_c('span',{staticClass:"text-light small"},[_vm._v(_vm._s(_vm.translate('cart.bundle.size', {
                bundleSize: _vm.bundleSize,
            })))])],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"delivery"},[_c('div',{staticClass:"d-flex align-items-center"},[[_c('Envelope',{staticClass:"envelope-icon mr-2"}),_vm._v(" "),_c('span',{staticClass:"text-light small"},[_vm._v(_vm._s(_vm.translate('cart.delivery.type')))])]],2)]),_vm._v(" "),(_vm.product.type === 'region')?_c('div',[(_vm.product.regionCode === 'ww')?_c('div',{staticClass:"d-flex align-items-center"},[_c('UIFlag',{staticClass:"mr-2 global",attrs:{"code":"ww"}}),_vm._v(" "),_c('span',{staticClass:"text-light small"},[_vm._v(_vm._s(_vm.translate('product.globallyRedeemable')))])],1):_c('div',{staticClass:"d-flex align-items-center"},[_c('UIFlag',{staticClass:"mr-2",attrs:{"code":_vm.product.regionCode}}),_vm._v(" "),_c('span',{staticClass:"text-light small"},[_vm._v(_vm._s(_vm.translate('cart.deliveryInCountry', {
                    country: _vm.translate('country.' + _vm.product.regionCode.toUpperCase()),
                })))])],1)]):_vm._e(),_vm._v(" "),(_vm.product.type === 'currency')?_c('div',[(_vm.product.currencyLocked)?_c('div',{staticClass:"d-flex align-items-center"},[_c('UIFlag',{staticClass:"mr-2",attrs:{"code":_vm.product.currencyCode}}),_vm._v(" "),_c('span',{staticClass:"text-light small"},[_vm._v(_vm._s(_vm.translate('product.currencyLocked', { currency: _vm.product.currencyCode.toUpperCase() })))])],1):_c('div',{staticClass:"d-flex align-items-center"},[_c('UIFlag',{staticClass:"global mr-2",attrs:{"code":"ww"}}),_vm._v(" "),_c('span',{staticClass:"text-light small"},[_vm._v(_vm._s(_vm.translate('product.globallyRedeemable')))])],1)]):_vm._e(),_vm._v(" "),(_vm.loyaltyPreview && _vm.product.dundleCoinsRewarded)?_c('LoyaltyCoinsPill',{staticClass:"d-none d-md-flex mt-1",attrs:{"coins-earned":_vm.product.dundleCoinsRewarded}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }